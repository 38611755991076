// TODO: Implement class for coloring text
.text-black {
    color: getColor(black);
}

.text-white {
    color: getColor(white);
}

.text-black-gray-1 {
    color: getColor(black-gray-1);
}

.text-black-gray-2 {
    color: getColor(black-gray-2);
}

.text-black-gray-3 {
    color: getColor(black-gray-3);
}

.text-black-gray-4 {
    color: getColor(black-gray-4);
}

.text-black-gray-500 {
    color: getColor(black-gray-500);
}

.text-blue-gray-1 {
    color: getColor(blue-gray-1);
}

.text-blue-gray-2 {
    color: getColor(blue-gray-2);
}

.text-blue-gray-3 {
  color: getColor(blue-gray-3);
}

.text-blue-gray-4 {
    color: getColor(blue-gray-4);
}

.text-blue-gray-700{
    color: getColor(blue-gray-700);
}

.text-blue-1 {
    color: getColor(blue-500);
}

.text-sunshade-5 {
    color: getColor(sunshade-500);
}

.text-sunshade-800 {
    color: getColor(sunshade-800);
}

.text-blue-500 {
    color: getColor(blue-500);
}

.text-turquoise-500 {
    color: getColor(turquoise-500);
}

.bg-turquoise-50 {
    background-color: getColor(turquoise-50);
}

.bg-turquoise-500 {
    background-color: getColor(turquoise-500);
}

.bg-blue-gray-4 {
    background-color: getColor(blue-gray-4);
}

.bg-backGround-blue {
    background-color: getColor(back-ground-blue);
}

.text-flamingo-900 {
    color: getColor(flamingo-900);
}

.text-flamingo-500 {
    color: getColor(flamingo-500);
}

.bg-flamingo-50 {
    background-color: getColor(flamingo-50);
}

.bg-flamingo-100 {
    background-color: getColor(flamingo-100);
}

.bg-sunshade-50 {
    background-color: getColor(sunshade-50);
}

.bg-sunshade-100 {
    background-color: getColor(sunshade-100);
}

.bg-sunshade-800 {
    background-color: getColor(sunshade-800);
}

.bg-blue-gray-5 {
    background-color: getColor(blue-gray-5);
}

.text-blue-50 {
    color: getColor(blue-50);
}

.bg-blue-400 {
    background-color: getColor(blue-400);
}

.bg-blue-500 {
    background-color: getColor(blue-500);
}

.bg-blue-gray-50 {
    background-color: getColor(blue-gray-50);
}

.bg-blue-50 {
    background-color: getColor(blue-50);
}

.text-marigo {
    color: getColor(marigo);
}

.bg-marigo {
    background-color: getColor(marigo);
}

.bg-marigo-50 {
    background-color: getColor(marigo-50);
}

.bg-white {
    background-color: getColor(white);
}