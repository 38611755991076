@import "@/scss/abstract/variables.scss";

.errorPage {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(228,239,251,1) 0%, rgba(199,221,247,1) 40%, rgba(143,187,239,1) 100%);
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  &__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      color: getColor(blue-1);
      margin-top: 6rem;
      font-size: 128px;
    }

    .sub-title {
      color: getColor(blue-2);
      margin-top: 3.5rem;
    }

    .description {
      color: getColor(white);
    }
  }
}