// ====================================
// Gotham
// ====================================
@font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/gotham/GothamRounded-Book.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/gotham/GothamRounded-Book.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/gotham/GothamRounded-Medium.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/gotham/GothamRounded-Bold.ttf) format('truetype');
  }
  
// ====================================
// Prompt
// ====================================
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/prompt/Prompt-Light.ttf) format('truetype');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }
  
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/prompt/Prompt-Regular.ttf) format('truetype');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }
  
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/prompt/Prompt-Medium.ttf) format('truetype');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }
  
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://statics.ooca.dev/fonts/prompt/Prompt-SemiBold.ttf) format('truetype');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }
  

.text-h1, h1 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 600;
font-size: 36px;
line-height: 40px;
}
  
.text-h2, h2 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 400;
font-size: 36px;
line-height: 36px;
}

.text-h3, h3 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 24px;
line-height: 30px;
}

.text-h4, h4 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 20px;
line-height: 24px;
}

.text-subheader1 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 14px;
line-height: 20px;
}

.text-subheader2 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 300;
font-size: 14px;
line-height: 20px;
}

.text-title1 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 20px;
line-height: 24px;
}

.text-title2 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 18px;
line-height: 22px;
}

.text-title3 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 16px;
line-height: 20px;
}

.text-body1 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 300;
font-size: 16px;
line-height: 20px;
}

.text-body2 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 14px;
line-height: 24px;
}

.text-body3 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 300;
font-size: 14px;
line-height: 24px;
}

.text-body4 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 12px;
line-height: 16px;
}

.text-body5 {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 300;
font-size: 12px;
line-height: 16px;
}

.text-button-large {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 20px;
line-height: 24px;
}

.text-button-small {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 500;
font-size: 16px;
line-height: 16px;
}

.text-button-underline {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 300;
font-size: 16px;
line-height: 16px;
text-decoration: underline;
}

.text-small {
font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
font-weight: 400;
font-size: 10px;
line-height: 10px;
}
