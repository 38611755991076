@import '../scss/abstract/variables.scss';

// Custom Bootstrap Maintheme before the lib is loaded.
$primary: #1F77DF;
$secondary: #EAE8FF;
$warning: #FF8F4B;
$danger: #EF7773;
$success: #8AD866;
$info: #55D6FF;

$btn-border-radius: 50px;

// Import Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';

/* 
    ==============================================================================================
                                        APOLLO 21 Dev, Listen up!
      Write your custom CSS styles which will override bootstrap default style only in this file!

                                                THANK YOU
    ==============================================================================================
*/

body {
    .modal-header {
        border-bottom: none;
        padding: 1.5rem;
    }

    .modal-body {
        padding: 1.5rem;
    }

    .modal-footer {
        border-top: none;
        padding: 1rem;
    }

    .modal-content {
        top: 10vh;
        border: none;
        box-shadow: 0 0.5rem 1rem rgba(#000, .15);
    }
}

/*
========== Buttons ==========
*/

body .btn {
    &:focus {
        box-shadow: none;
    }
    min-width: 100px;
}

// Overrides BS theme color (Success & Danger)
.btn {
    &-success, &-danger {
        color: white;
        &:hover{
            color: white;
        }
    }
    &-outline-success, &-outline-danger {
        &:hover{
            color: white;
        }
    }
}

body .btn-primary, body .btn-secondary {
    &:focus {
        box-shadow: none;

        &:active {
            box-shadow:none;
        }
    }
}

body .btn-primary {
    color: getColor(white);
    background-color: getColor(blue-500);
    border: none;
    box-shadow: getColor(elevation-3);

    &:hover, &:focus {
        color: white;
        background-color: getColor(blue-200);
        box-shadow: getColor(elevation-4);

        &:active {
            background-color: getColor(blue-300);
        }
    }

    &:disabled {
        color: white;
        background-color: getColor(black-gray-1);
        box-shadow: none;
    }
}

body .btn-secondary {
    color: getColor(blue-500);
    background-color: white;
    border-radius: 5px;
    border: none;
    box-shadow: getColor(elevation-3);

    &:hover, &:focus {
        color: getColor(blue-500);
        background-color: white;
        box-shadow: getColor(elevation-4);

        &:active {
            color: getColor(blue-300);
        }
    }

    &:disabled {
        color: getColor(black-gray-1);
        background-color: white;
    }
}

/*
========== Dropdowns ==========
*/
body .dropdown-item {
    &:hover {
        background-color:  getColor(blue-300);
    }
}