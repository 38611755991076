@import '@/scss/abstract/variables.scss';

.resend-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resend-link {
    border: 0;
    background-color: transparent;
    color: getColor(blue-500);
}