@import '@/scss/abstract/variables.scss';

.navbar {
    z-index: 1;
    color: getColor(black);
    height: $navbar-height;
    background: transparent;
    height: 112px;
    width: 100%;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 3;

        &__left {
            width: 50%;
            display: flex; // Flex inside left container
            align-items: center;
            justify-content: flex-start;
            padding-left:20px ;
        }

        &__right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 20px;
        
            .icon__user{
                width: 24px;
                height: 24px;
                margin-left: 16px;
            }
        }
    }

}