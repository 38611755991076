:root {
  // Theme
  --primary: #1F77DF;         // used in primary button
}

// Variables

// Navs
$navbar-height: 64px;
$sidebar-width: 265px;
$sidebar-collapsed-width: 120px;


$palette-map: (
    blue-50:#E4EFFB,
    blue-100:#C7DDF7,
    blue-200:#8FBBEF,
    blue-300:#62A0E9,
    blue-400:#418BE4,
    blue-500:#1F77DF,
    blue-600:#1B6FDB,
    blue-700:#1764D7,
    blue-800:#125AD2,
    blue-900:#0A47CA,

    turquoise-50:#E0F8F6,
    turquoise-100:#B3EDE8,
    turquoise-200:#80E2D9,
    turquoise-300:#4DD6CA,
    turquoise-400:#26CDBE,
    turquoise-500:#00C4B3,
    turquoise-600:#00BEAC,
    turquoise-700:#00B6A3,
    turquoise-800:#00AF9A,
    turquoise-900:#00A28B,

    marigo: #F9A000,
    marigo-50:#FCF4E0,
    marigo-100:#F8E4B3,
    marigo-200:#F4D280,
    marigo-300:#F0BF4D,
    marigo-400:#ECB226,
    marigo-500:#E9A400,
    marigo-600:#E69C00,
    marigo-700:#E39200,
    marigo-800:#DF8900,
    marigo-900:#D97800,

    flamingo-50:#FDEFEE,
    flamingo-100:#FAD6D5,
    flamingo-200:#F7BBB9,
    flamingo-300:#F4A09D,
    flamingo-400:#F18B88,
    flamingo-500:#EF7773,
    flamingo-600:#ED6F6B,
    flamingo-700:#EB6460,
    flamingo-800:#E85A56,
    flamingo-900:#E44743,

    sunshade-50:#FFF2E9,
    sunshade-100:#FFDDC9,
    sunshade-200:#FFC7A5,
    sunshade-300:#FFB181,
    sunshade-400:#FFA066,
    sunshade-500:#FF8F4B,
    sunshade-600:#FF8744,
    sunshade-700:#FF7C3B,
    sunshade-800:#FF7233,
    sunshade-900:#FF6023,

    guava-50:#F1FAED,
    guava-100:#DCF3D1,
    guava-200:#C5ECB3,
    guava-300:#ADE494,
    guava-400:#9CDE7D,
    guava-500:#8AD866,
    guava-600:#82D45E,
    guava-700:#77CE53,
    guava-800:#6DC849,
    guava-900:#5ABF38,

    blue-gray-50:#EAEFF5,
    blue-gray-100:#CDD8E1,
    blue-gray-200:#B0BDCA,
    blue-gray-300:#91A3B4,
    blue-gray-400:#7A8FA2,
    blue-gray-500:#637B91,
    blue-gray-600:#566D80,
    blue-gray-700:#465969,
    blue-gray-800:#374654,
    blue-gray-900:#26313C,

    black:#000000,
    black-gray-1:#C4C4C4,
    black-gray-2:#F1F1F1,
    black-gray-3:#F6F6F6,
    black-gray-4:#788697,
    black-gray-500:#A6A6A6,
    white:#FFFFFF,
    blue-gray-1:#465969,
    blue-gray-2:#7A8FA2,
    blue-gray-3:#CDD8E1,
    blue-gray-4:#EAEFF5,
    blue-gray-5:#E4EFFB,
    blue-1:#1F77DF,
    blue-2:#62A0E9,
    blue-3:#8FBBEF,
    blue-4:#C7DDF7,
    blue-5:#E4EFFB,
    back-ground-blue: rgba(205, 216, 225, 0.8),

    black-gardient:linear-gradient(180deg, rgba(71, 76, 85, 0) 0%, rgba(0, 0, 0, 0.9) 100%),
    blue-gardient:linear-gradient(180deg, #E4EFFB 41.34%, rgba(228, 239, 251, 0) 84.37%),

    //shadow
    elevation-1: 0px 2px 8px rgba(31, 119, 223, 0.04),
    elevation-2: 0px -2px 16px rgba(31, 119, 223, 0.08),
    elevation-3: 0px 8px 16px rgba(31, 119, 223, 0.08),
    elevation-4: 0px 8px 16px rgba(31, 119, 223, 0.08),
    elevation-5: 0px 8px 16px -4px rgba(31, 119, 223, 0.08),
    elevation-6-1: 0px 4px 8px -4px rgba(31, 119, 223, 0.08), 
    elevation-6-2: 0px 16px 24px rgba(31, 119, 223, 0.08),
    elevation-7-1: 0px 4px 12px -4px rgba(31, 119, 223, 0.12),
    elevation-7-2: 0px 16px 32px rgba(31, 119, 223, 0.16),
    elevation-8-1: 0px 4px 12px -4px rgba(31, 119, 223, 0.12),
    elevation-8-2: 0px 16px 32px rgba(31, 119, 223, 0.16),
);

@function getColor($name) {
  @return map_get($palette-map, $name);
}