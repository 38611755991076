@import "@/scss/abstract/variables.scss";

.title-container{
    width: 400px;
    margin: 1rem 2rem;
}

.back-button {
    border: 2px solid getColor(blue-500);
    border-radius: 50px;
    background-color: getColor(white);
    color: getColor(blue-500);
    height: 40px;
}

.btn-primary {
    color: getColor(white);
    background-color: getColor(blue-500);
    border: none;
    box-shadow: getColor(elevation-3);
    height: 40px;
}
