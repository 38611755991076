@import '@/scss/abstract/variables.scss';
@import '@/scss/font.scss';

.login-container {
    width: 560px;
    height: 528px;
    align-items: center;
    border-radius: 20px;
    border: none;

    .ooca-logo{
        margin-top: 48px;
        height: 40px;
        width: 102px;
    }

    .wrapper-title{
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .input-login-form {
        align-items: center;
        ::-ms-reveal {
            display: none;
        }

        .form-control.is-invalid {
            background-image: none;
            border-color: getColor(flamingo-500);
        }
        
        .group-password{
            margin-top: 16px;
        }

        .group-email{
            height: 75px;
        }

        #eye-view-password{
            width: 38px;
            height: 38px;
            background-color: transparent;
            border: none;
            position: absolute;
            right: 1%;
            top: 3%;
            bottom:3%;
            cursor: pointer;
            svg{
                fill: getColor(blue-gray-1);
            }
        }

        .btn-login{
            width: 340px;
            height: 40px;
            margin-top: 32px;
            margin-bottom: 16px;
        }

        .ooca-text-box{
            width: 327px;
            height: 38px;
            border-radius: 8px;
            border-color: getColor(black-gray-1);
    
            &:focus{
                border-color: getColor(blue-500);
                border-width: 1px;
                caret-color: getColor(blue-500);
                box-shadow: none;
            }
    
            &:disabled{
                background-color: getColor(black-gray-3);
                border-color: getColor(blue-gray-4);
                border-width: 1px;
                color: getColor(black-gray-1);
            }
    
            &:read-only{
                background-color: getColor(white);
                border-color: getColor(blue-gray-4);
                color: getColor(blue-gray-1);
            }

            .error-password-message{
                color: getColor(flamingo-500);
            }
        }
    }

    .link-forgot-password{
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

