@use "../../scss/animation.scss";
@import "../../scss/abstract/variables.scss";

$nav-item-height: 3.45rem;

.sidebar {
    z-index: 0;
    color: getColor(blue-gray-2);
    fill: getColor(blue-gray-2);
    background-color: getColor(white);
    filter: drop-shadow(getColor(elevation-1));
    max-width: $sidebar-width;
    max-height: 100vh;
    transition: ease .5s;

     // Put center and bottom apart
     flex-direction: column;
     display: flex;
    // justify-content: space-between;

    .item-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .logo{
      text-align: center;
      margin-top: 40px;
      margin-bottom: 48px;
    }

    .dropdown-menu{
      border-radius: 20px;
      border: 0;
      box-shadow: getColor(elevation-2);
      padding: 16px;
      &:hover {
        .dropdown-item {
          color: getColor(blue-300);
          fill:  getColor(blue-300);
          background-color: getColor(white);
          transition: ease .5s;
        }
        svg {
          animation: swing ease .5s 1 alternate;
        }
        .item-svg{
          fill:getColor(blue-300);      
          stroke:getColor(blue-300);  
        }
        .side-group {
          &__arrow {
            color: getColor(blue-300);
            fill: getColor(blue-300);
          }
        }
      }
    }

    // Sidebar collapsed style & animation
    &.collapsed {
      max-width: $sidebar-collapsed-width;
    //  transform: rotate(-7deg);
      transition: ease .4s;

      .sidebar-toggle-button{
        opacity: 1;
        transition: ease .4s;
      }

      span{
        display: none;
        transition: ease .5s;
      }     

      .nav-item{
        &.selected {
          &::after {
            width: calc($sidebar-collapsed-width - 48px );
            left: 24px;
            right: 24px;
          }
        }

        .sidebar-content{
          display: flex;
          justify-content: center;
          &.side-group__item{
            justify-content: flex-start;
            align-items: center;
          }
        } 

        &.overlay-nav-item{
          display: flex ;
          span{
            display: block;
            opacity: 1;
            transition: ease .6s;
          }      
        }
      }
      .side-group {   
        &__arrow {
          display: none;
        }
      }
      .main-item {
        display: none;
      }

      .bottom {
        justify-content: center;
      }
    }

    .side-group {
      &__item {
       // margin-left: 1.5rem;
        color: getColor(blue-gray-2);
      }

      &__arrow {
        cursor: pointer;
        margin-right: 0.8rem;
        color: getColor(blue-gray-2);
        fill: getColor(blue-gray-2);
      }
    }

    .top {     
        padding-left: 24px; 
        padding-right: 24px;  
        .nav {
          margin-top: 4px;
        }
    }

    .bottom {
      height: 96px;
      padding-left: 24px; 
      padding-right: 24px;  
      display: flex;
      align-items: center;
      border-top: 1px solid getColor(black-gray-2);
    }

    .side-group, .nav-item  {
      white-space: nowrap;
      height: $nav-item-height;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;

      .sidebar-content {
        color: getColor(blue-gray-2); // Specifically overrides Bootstrap styles
        fill: getColor(blue-gray-2); // Specifically overrides Bootstrap styles
      }

      .nav-link {
        width: 100%;
      }

      span {
        margin-left: 16px;
        font-size: 1rem;
      }

      &.overlay-nav-item{
        display: none;
      }

      &:hover  {
        .dropdown-item{
          color: getColor(blue-300);
          fill:  getColor(blue-300);
          transition: ease .5s;
        }
        .sidebar-content {
          color: getColor(blue-300);
          fill:  getColor(blue-300);
          transition: ease .5s;
        }
        svg {
          animation: swing ease .5s 1 alternate;
        }
        .item-svg{
          fill:getColor(blue-300);      
          stroke:getColor(blue-300);  
        }
        .side-group {
          &__arrow {
            color: getColor(blue-300);
            fill: getColor(blue-300);
          }
        }
      }

      &::after {
        content: '';
        background-color: getColor(blue-50);
        border-radius: 8px;
        width: 0;
        height: $nav-item-height;
        position: absolute;
        transition: ease .4s;
        z-index: -1;
        left: 50%;
        right: 50%;
      }

      &.selected {
        &::after {
          width: calc($sidebar-width - 48px );
          left: 24px;
          right: 24px;
        }

        &:not(.dropdown-menu){
          .sidebar-content, .side-group__item , .side-group__arrow,.item-svg {
            color: getColor(blue-500);
            fill: getColor(blue-500);
          }
        }
      }

      &.group-selected{
        background-color: getColor(black-gray-3);
        border-radius: 8px;
        .item-svg{
          fill:getColor(blue-500);      
          stroke:getColor(blue-500);  
        }

        .sidebar-content, .side-group__item, .side-group__arrow {
          color: getColor(blue-500);
          fill: getColor(blue-500);
        }
      }

      &.dropdown{
          .dropdown-toggle{
          &::after {
            display: none;
          }
        }
      }

      .item-svg{
        fill:getColor(blue-gray-2);      
        stroke:getColor(blue-gray-2);  
      }
    }

    .dropdown-item{
      margin-bottom: 4px;
      margin-top: 4px;
      .sidebar-content {
        color: getColor(blue-gray-2) !important;
        svg{
          fill: getColor(blue-gray-2) !important; 
          stroke: getColor(blue-gray-2) !important; 
        }
        
      }
      
      &:hover {
        .sidebar-content {
          color: getColor(blue-300) !important;
          svg{
            fill: getColor(blue-300) !important; 
            stroke: getColor(blue-300) !important; 
          }     
        }
      }

      &.selected {
        background-color: getColor(blue-50) !important;
        border-radius: 8px;
        .sidebar-content {
          color: getColor(blue-500) !important;
          svg{
            fill: getColor(blue-500) !important; 
            stroke: getColor(blue-500) !important; 
          }     
        }
      }

     
    }
     
    &:hover {
      .sidebar-toggle-button{
        opacity: 1;
      }
    }

    .sidebar-toggle-button{
      background-color: getColor(white);
      position: absolute;
      opacity: 0;
      top: calc(50vh - 12px);
      right: -11px;
      border-radius: 50%;
      height: 24px;
      outline: 0px;
      padding: 0px;
      width: 24px;
      border: none;
      transition: ease .5s;
      box-shadow: getColor(elevation-5);

      svg{
        fill: getColor(blue-500);
      }

      &:hover {
        background-color: getColor(blue-300);
        svg{
          fill: getColor(white);
        }
      }
    }
}
